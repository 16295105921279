import { Tonality } from '@dsny/dsny-component-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { promotedSongsData } from 'src/consts/PromotedSongsData';
import {
  ComboboxInputStyles,
  ComboboxOptionWrapper,
  ComboboxPopoverStyles,
  ComboboxStyles,
  ItemWrapper,
  InputLabelStylesPromotedSongs,
  ComboboxWrapper,
} from './SignupFields.styles';

interface Props {
  onSelect: (item: string) => void;
  ref?: null;
}

const SignupPromotedSongs: React.FC<Props> = ({ onSelect }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>('');

  const openCombobox = () => {
    setOpen(true);
  };

  const closeCombobox = () => {
    setOpen(false);
  };

  // Handling promoted songs selection
  const onChange = (option: string) => {
    setValue(option);
    onSelect(option);
  };

  return (
    <>
      {/* Promoted songs */}
      <ComboboxWrapper>
        <Tonality.Combobox
          label={t('SIGNUP_PROMOTED_SONGS')}
          isOpen={open}
          onClick={openCombobox}
          onClose={closeCombobox}
          onChange={onChange}
          value={value}
          setting="mtr"
          style={{ ...ComboboxStyles }}
        >
          <Tonality.ComboboxInput
            placeholder={t('SIGNUP_SELECT_PLACEHOLDER')}
            style={{ ...ComboboxInputStyles }}
            inputLabelStyles={{ ...InputLabelStylesPromotedSongs }}
          />
          <Tonality.ComboboxPopover style={{ ...ComboboxPopoverStyles }}>
            <Tonality.ComboboxList
              style={{ paddingTop: '16px', paddingBottom: '16px' }}
            >
              {promotedSongsData?.map((promoted) => (
                <ComboboxOptionWrapper
                  key={promoted.id}
                  value={promoted.description}
                  isSelected={promoted.description === value}
                  style={{ paddingLeft: '16px' }}
                >
                  <ItemWrapper>{promoted.description}</ItemWrapper>
                </ComboboxOptionWrapper>
              ))}
            </Tonality.ComboboxList>
          </Tonality.ComboboxPopover>
        </Tonality.Combobox>
      </ComboboxWrapper>
    </>
  );
};

export default SignupPromotedSongs;
