/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Button } from '@dsny/dsny-component-library';
import { getSessionUser } from 'src/features/Auth';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import {
  FlexButtonWrapper,
  ModalContainerStyles,
  ModalDescWrapper,
  ModalHeadingWrapper,
} from './EnterprisePlanLimitModal.styles';

type ZohoWindow = typeof window & {
  $zohosq?: {
    visitor?: {
      name: (name: string) => void;
      email: (email: string) => void;
    };
    chat?: {
      defaultdepartment?: (dept: string) => void;
    };
  };
  $zoho?: {
    salesiq?: {
      chat?: {
        defaultdepartment?: (dept: string) => void;
      };
      floatwindow?: {
        visible?: (state: 'show' | 'hide') => void;
      };
    };
  };
};

export const EnterprisePlanLimitModal = ({ showModal, setShowModal }: any) => {
  const { t } = useTranslation();

  const { enterpriseProfile } = useSelector(
    (state: RootState) => state.dashboard
  );

  // Handling open zoho chat
  const handleOpenZohoChat = () => {
    const { fullname, email } = getSessionUser();
    const zohoWindow = window as ZohoWindow;

    const setName = zohoWindow?.$zohosq?.visitor?.name;
    const setEmail = zohoWindow?.$zohosq?.visitor?.email;
    const setDepartment = zohoWindow?.$zoho?.salesiq?.chat?.defaultdepartment;
    const setVisible = zohoWindow?.$zoho?.salesiq?.floatwindow?.visible;

    setName?.(fullname);
    setEmail?.(email);
    setDepartment?.('Business Inquiries');
    setVisible?.('show');
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      containerStyles={{ ...ModalContainerStyles }}
    >
      <ModalHeadingWrapper>
        {t('ENTERPRISE_PLAN_LIMIT_MODAL_TITLE')}
      </ModalHeadingWrapper>

      <ModalDescWrapper>
        <Trans
          i18nKey="ENTERPRISE_PLAN_LIMIT_MODAL_DESC"
          values={{ limit: enterpriseProfile.songs_limit }}
          components={{ bold: <strong /> }}
        />
      </ModalDescWrapper>

      <FlexButtonWrapper>
        {/* Close button */}
        <Button
          setting="mtr"
          variant="tertiary"
          type="button"
          onClick={() => setShowModal(false)}
        >
          {t('ENTERPRISE_CLOSE_MODAL_BUTTON')}
        </Button>
        {/* Contact us button */}
        <Button
          setting="mtr"
          type="button"
          onClick={() => handleOpenZohoChat()}
        >
          {t('ENTERPRISE_CONTACT_US_MODAL_BUTTON')}
        </Button>
      </FlexButtonWrapper>
    </Modal>
  );
};
