import { Button } from '@dsny/dsny-component-library';
import { device, mtrStyle } from 'src/styles/global';
import theme from 'src/styles/theme';
import styled from 'styled-components';

export const SummaryBox = styled.div`
  display: flex;
  flex-direction: column;
  position: inherit;
  padding: 32px 32px;
  border-radius: ${mtrStyle.borderRadius};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background-color: ${theme.colors.white0};

  @media ${device.mobile} {
    padding: 24px 24px;
  }
`;

export const GoBackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  font-size: 14px;
  color: ${theme.colors.neutralW20};

  @media ${device.mobile} {
    padding-bottom: 24px;
    font-size: 12px;
  }
`;

export const ClickableGoBack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 4px;

  svg {
    width: 14px;
    height: 14px;
    color: ${theme.colors.neutralW30};
  }
`;

export const SummaryTitle = styled.h2`
  padding-bottom: 24px;
  color: ${theme.colors.neutralC20};
`;

export const ExpirationDateWrapper = styled.div`
  padding-bottom: 8px;
  font-size: 14px;
  color: ${theme.colors.neutralW40};
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.neutralW80};
  border-radius: ${mtrStyle.borderRadius};
  margin-bottom: 24px;
  padding: 16px;
`;

export const SummaryInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const SummaryCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const SummaryInfo = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  color: ${theme.colors.neutralW20};

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${theme.colors.neutralW40};

    :first-of-type {
      margin-top: 8px;
    }
  }
`;

export const BilllingInfo = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.neutralW20};
`;

export const RegularPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.mobile} {
    flex-direction: column;
    line-height: 5px;
  }
`;

export const PromoPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PlanWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SummaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 8px;
  }
`;

export const EnterpriseSummaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  padding-top: 8px;

  @media ${device.mobile} {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 8px;
  }
`;

export const PayButton = styled(Button)`
  min-width: 180px;
  height: 36px;

  @media ${device.mobile} {
    width: 100%;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const StartTrackingButton = styled(Button)`
  min-width: 120px;
  height: 36px;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const SpinningStyleWrapper = styled.div`
  padding-top: 8px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const FeesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const TooltipWrapper = styled.span``;

export const IconWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 2px 0px 0px 5px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const StripeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;

  @media ${device.mobile} {
    justify-content: center;
  }
`;

export const StripeNote = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.neutralW40};
`;

export const LockIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const ModalContainerStyles = {
  width: '608px',
  height: 'fit-content',
  padding: '32px',
};
