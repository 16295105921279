const enterpriseProfileKey = 'ENTERPRISE_PLAN_KEY';

export const setEnterprisePlanToLocalStorage = (value: boolean) => {
  localStorage.setItem(enterpriseProfileKey, JSON.stringify(value));
};

export const getEnterprisePlanFromLocalStorage = () => {
  const storedValue = localStorage.getItem(enterpriseProfileKey);
  return storedValue ? JSON.parse(storedValue) : undefined;
};

export const deleteEnterprisePlanFromLocalStorage = () => {
  localStorage.removeItem(enterpriseProfileKey);
};
