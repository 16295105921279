import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubTitle, Title } from './StepOneTitle.styles';

const StepOneTitleEnterprise: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Step-one title and subtitles - (Enterprise) */}
      <Title>{t('ENTERPRISE_MULTI_ASSET_TITLE')}</Title>
      <SubTitle>{t('ENTERPRISE_MULTI_ASSET_SUBTITLE')}</SubTitle>
    </>
  );
};

export default StepOneTitleEnterprise;
