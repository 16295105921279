/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { SidebarNavItem } from '@dsny/dsny-component-library';
import { SidebarFooterWrapper } from './Sidebar.styles';

type SidebarFooterProp = {
  isExpanded: boolean;
  onClick: any;
};

const SidebarFooter = ({ isExpanded, onClick }: SidebarFooterProp) => {
  const { t } = useTranslation();

  // handling onclick navigation
  const handleOnclick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onClick();
  };

  return (
    <SidebarFooterWrapper>
      <SidebarNavItem
        as={NavLink}
        url="#"
        tabIndex={0}
        onClick={handleOnclick as any} // passing event to prevent default navigation
        iconName={isExpanded ? 'Collapse' : 'Expand'}
        label={t(isExpanded ? 'Collapse' : 'Expand')}
        isExpanded={isExpanded}
        setting="mtr"
        mode="desktop"
        style={{
          textDecoration: 'none',
        }}
      />
    </SidebarFooterWrapper>
  );
};

export default SidebarFooter;
