import { MaterialIcon } from '@dsny/dsny-component-library';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import theme from 'src/styles/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { TrackingStatusEnum, selectPeriod } from '../Dashboard.slice';
import {
  ActivePlanWrapper,
  ExpiredPlanWrapper,
  IconWrapper,
  PlanButton,
  SampleSongWrapper,
  HiphenUnicode,
  WarningPlanWrapper,
  ScheduledPlanWrapper,
} from './TrackingCard.styles';

const sampleSongId = 'sample-song-200000';

interface TrackingCardProps {
  setShowEnterpriseLimit: (value: boolean) => void;
  setShowEnterpriseExtend: (value: boolean) => void;
}

const TrackingCard: React.FC<TrackingCardProps> = ({
  setShowEnterpriseLimit,
  setShowEnterpriseExtend,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const openExtendModal = queryParams.get('openExtendModal');

  const { selectedSong, enterpriseProfile } = useSelector(
    (state: RootState) => ({
      selectedSong: state.dashboard.songSelection?.selectedSong,
      enterpriseProfile: state.dashboard.enterpriseProfile,
    })
  );

  // Handling extend-renew navigation for enterprise users
  const handleExtendRenewNavigation = () => {
    if (
      enterpriseProfile.isEnterpriseProfile &&
      enterpriseProfile.songs_limit &&
      (selectedSong?.selected_tracking_period?.status === 'EXPIRED' ||
        selectedSong?.selected_tracking_period?.status === 'PAST_EXPIRED') &&
      (enterpriseProfile.active_songs_count || 0) >=
        enterpriseProfile.songs_limit
    ) {
      setShowEnterpriseLimit(true);
    } else if (enterpriseProfile.isEnterpriseProfile) {
      setShowEnterpriseExtend(true);
    } else {
      navigate('/payment/selectpackage');
    }
  };

  // Handling open extnd modal parameter
  if (openExtendModal && enterpriseProfile.isEnterpriseProfile) {
    handleExtendRenewNavigation();
    queryParams.delete('openExtendModal');
    const newSearch = queryParams.toString();
    const newUrl = newSearch
      ? `${location.pathname}?${newSearch}`
      : location.pathname;
    navigate(newUrl, { replace: true });
  }

  // Handling remaining days to start scheduled state
  const getRemainingDaysToStart = () => {
    const currentDate = new Date().getTime();
    const targetDate = new Date(
      selectedSong?.selected_tracking_period?.tracking_period
        .start_tracking_on || new Date()
    ).getTime();

    const differenceInMilliseconds = targetDate - currentDate;
    const differenceInDays = Math.ceil(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    return differenceInDays;
  };

  /* Sample song - song_id: sample-song-200000 */
  const SampleSongCard = () => (
    <SampleSongWrapper>
      <IconWrapper>
        <MaterialIcon name="Schedule" filled={false} />
      </IconWrapper>
      <div>
        {selectedSong?.selected_tracking_period?.remainingDays}{' '}
        {t('DASHBOARD_REMAINING_DAYS')}
      </div>
    </SampleSongWrapper>
  );

  /* Scheduled state - is the song that it’s coming into the tracking queue */
  const ScheduledCard = () => {
    const daysTillStart = getRemainingDaysToStart();

    return (
      <ScheduledPlanWrapper>
        <IconWrapper>
          <MaterialIcon name="Schedule" filled={false} />
        </IconWrapper>
        <div>
          <Trans
            i18nKey={
              daysTillStart > 1
                ? 'DASHBOARD_TRACKING_STARTS_IN_DAYS'
                : 'DASHBOARD_TRACKING_STARTS_IN_DAY'
            }
            values={{ number: getRemainingDaysToStart() }}
          />
        </div>
      </ScheduledPlanWrapper>
    );
  };

  /* Active state - is the default plan state */
  const ActiveCard = () => (
    <ActivePlanWrapper>
      <IconWrapper>
        <MaterialIcon name="Schedule" filled={false} />
      </IconWrapper>
      <div>
        {selectedSong?.selected_tracking_period?.remainingDays}{' '}
        {t('DASHBOARD_REMAINING_DAYS')}
      </div>{' '}
      &nbsp;&nbsp;<HiphenUnicode>-</HiphenUnicode>
      <PlanButton onClick={() => handleExtendRenewNavigation()}>
        {t('DASHBOARD_EXTEND_PLAN')}
      </PlanButton>
    </ActivePlanWrapper>
  );

  /* Expiring state - will appear when there are 5 or less days remaining within a plan */
  const WarningCard = () => (
    <WarningPlanWrapper>
      <IconWrapper>
        <MaterialIcon
          name="Schedule"
          filled={false}
          color={theme.colors.alert40}
        />
      </IconWrapper>
      <div>
        {selectedSong?.selected_tracking_period?.remainingDays}{' '}
        {t('DASHBOARD_REMAINING_DAYS')}
      </div>{' '}
      &nbsp;&nbsp;<HiphenUnicode>-</HiphenUnicode>
      <PlanButton onClick={() => handleExtendRenewNavigation()}>
        {t('DASHBOARD_EXTEND_PLAN')}
      </PlanButton>
    </WarningPlanWrapper>
  );

  /* Expired state - within 90 day fingerprint period */
  const ExpiredCard = () => (
    <ExpiredPlanWrapper>
      <IconWrapper>
        <MaterialIcon
          name="Schedule"
          filled={false}
          color={theme.colors.error50}
        />
      </IconWrapper>
      <div>{t('DASHBOARD_EXPIRED_PLAN')}</div> &nbsp;&nbsp;
      <HiphenUnicode>-</HiphenUnicode>
      <PlanButton onClick={() => handleExtendRenewNavigation()}>
        {t('DASHBOARD_EXTEND_PLAN')}
      </PlanButton>
    </ExpiredPlanWrapper>
  );

  /* Expired state – past 90 day fingerprint period */
  const ExpiredCardPast90 = () => (
    <ExpiredPlanWrapper>
      <IconWrapper>
        <MaterialIcon
          name="Schedule"
          filled={false}
          color={theme.colors.error50}
        />
      </IconWrapper>
      <div>{t('DASHBOARD_EXPIRED_PLAN')}</div> &nbsp;&nbsp;
      <HiphenUnicode>-</HiphenUnicode>
      <PlanButton onClick={() => handleExtendRenewNavigation()}>
        {t('DASHBOARD_RENEW_PLAN')}
      </PlanButton>
    </ExpiredPlanWrapper>
  );

  const updateRenderedComponent = () => {
    // select first period in the list if selected_tracking_period is undefinded
    if (!selectedSong?.selected_tracking_period) {
      dispatch(selectPeriod(0));
    }

    if (selectedSong?.song_id === sampleSongId) {
      return <SampleSongCard />;
    }
    if (selectedSong?.is_scheduled) {
      return <ScheduledCard />;
    }
    switch (selectedSong?.selected_tracking_period?.status) {
      case TrackingStatusEnum.EXPIRING:
        return <WarningCard />;
      case TrackingStatusEnum.EXPIRED:
        return <ExpiredCard />;
      case TrackingStatusEnum.PAST_EXPIRED:
        return <ExpiredCardPast90 />;
      default:
        return <ActiveCard />;
    }
  };

  return updateRenderedComponent();
};

export default TrackingCard;
