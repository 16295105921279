import {
  Banner,
  Icon,
  MaterialIcon,
  SpinningIcon,
} from '@dsny/dsny-component-library';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'src/app/store';
import { formatDateForServer } from 'src/utils/Formatter';
import {
  AddPaymentObj,
  getCurrentPaymentMethod,
  resetPaymentPrepate,
} from '../AddPayment';
import {
  Container,
  ErrorWrapper,
  FormButton,
  PurchaseWrapper,
} from '../Purchase.styles';
import { PurchaseCancelModal } from '../PurchaseCancelModal';
import { PurchaseSuccessModal } from '../PurchaseSuccessModal';
import {
  ClickableGoBack,
  StartTrackingButton,
  GoBackWrapper,
  SummaryBox,
  SummaryTitle,
  EnterpriseSummaryButtonWrapper,
  SpinningStyleWrapper,
} from './Summary.styles';
import { SummaryDeleteSongModal } from './SummaryDeleteSongModal';
import {
  AddTrackList,
  enterpriseUploadFilesToPlaylist,
  resetStateAddTrack,
} from '../MultiAsset';
import UploadedTrackList from './UploadedTrackList/UploadedTrackList';
import { ImportReleaseSummaryDeleteModal } from '../MultiAsset/ImportRelease/ImportReleaseSummaryDeleteModal';
import { ImportReleaseCancelModal } from '../MultiAsset/ImportRelease/ImportReleaseCancelModal';

const SummaryEnterprise: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [showCancel, setShowCancel] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDeleteSong, setShowDeleteSong] = useState(false);
  const [showDeleteSongRelease, setShowDeleteSongRelease] = useState(false);
  const [songId, setSongId] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const { addPayment, selectPackage } = useSelector((state: RootState) => ({
    addPayment: state.addPayment,
    selectPackage: state.selectPackage,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isFetching) {
      setIsFetching(addPayment.isFetching);
    }
  }, [addPayment.isFetching]);

  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const { enterpriseProfile } = useSelector(
    (state: RootState) => state.dashboard
  );

  const {
    tracks,
    releaseTrack,
    trackIds,
    isEnterpriseTrackSuccessful,
    hasError,
  }: AddTrackList = useSelector((state: RootState) => state.multiAsset);

  const { hasError: hasPaymentError }: AddPaymentObj = useSelector(
    (state: RootState) => state.addPayment
  );

  const { currentPaymentMethod, purchaseCheckout } = addPayment;

  useEffect(() => {
    if (!currentPaymentMethod?.id) {
      dispatch(getCurrentPaymentMethod(true));
    }
  }, [currentPaymentMethod]);

  useEffect(() => {
    if (trackIds.length && !enterpriseProfile.isEnterpriseProfile) {
      dispatch(resetStateAddTrack());
      navigate('/dashboard');
    }
  }, [trackIds]);

  useEffect(() => {
    if (!tracks?.length && !selectedSong) {
      navigate('/payment/multiasset');
    }
  }, []);

  useEffect(() => {
    setIsFetching(false);
    if (purchaseCheckout.isPurchaseSuccessful || isEnterpriseTrackSuccessful) {
      setShowSuccess(true);
    }
  }, [purchaseCheckout, isEnterpriseTrackSuccessful]);

  // Handling tracks list
  const getTracksListForServerUpload = () => {
    return (
      tracks?.map((track) => {
        return {
          track_id: track.releaseTrack?.trackid || track.track_id || null,
          artist: track?.artist || track.releaseTrack?.artist,
          title: track?.title || track.releaseTrack?.title,
          upload_s3_bucket: track.S3File?.bucket,
          upload_s3_key: track.S3File?.key,
          src_filename: track.file?.name || '',
          start_date: formatDateForServer(
            track.startTrackingDate || new Date()
          ).toString(),
          version: '',
        };
      }) || []
    );
  };

  // Handling start tracking button
  const trackingBtnHandler = () => {
    dispatch(
      enterpriseUploadFilesToPlaylist({
        release_id: releaseTrack?.release_id || '',
        identifier: uuid().replace(/-/gi, ''),
        items: getTracksListForServerUpload(),
      })
    );
  };

  // Handling delete song
  const deleteSong = (id: string, isRelease: boolean) => {
    setSongId(id);
    if (isRelease) {
      setShowDeleteSongRelease(true);
    } else {
      setShowDeleteSong(true);
    }
  };

  // Unable to complete transaction banner
  const serverErrorMessage = {
    title: t('ENTERPRISE_SUMMARY_ERROR_BANNER_TITLE'),
    description: t('ENTERPRISE_SUMMARY_ERROR_BANNER_DESC'),
  };

  // Handling go back button
  const handleGoBackButton = () => {
    dispatch(resetPaymentPrepate());
    navigate('/payment/multiasset');
  };

  return (
    // Summary - Enterprise flow
    <PurchaseWrapper>
      <Container>
        <SummaryBox>
          {/* Banner messages */}
          {hasError && (
            <ErrorWrapper>
              <Banner
                bannerContent={serverErrorMessage}
                variant="error"
                width="100%"
              />
            </ErrorWrapper>
          )}

          {/* Go back button */}
          <GoBackWrapper>
            <ClickableGoBack
              onClick={() => {
                handleGoBackButton();
              }}
            >
              <Icon name="ArrowLeft" />
              {t('SUMMARY_GO_BACK_BUTTON')}
            </ClickableGoBack>
          </GoBackWrapper>

          {/* Title */}
          <SummaryTitle>{t('SUMMARY_TITTLE')}</SummaryTitle>

          {/* Uploaded track list */}
          <UploadedTrackList deleteSong={deleteSong} />

          {/* Summary buttons */}
          <EnterpriseSummaryButtonWrapper>
            {/* Cancel button */}
            <FormButton
              setting="mtr"
              variant="tertiary"
              type="button"
              onClick={() => setShowCancel(true)}
            >
              {t('PURCHASE_CANCEL_BUTTON')}
            </FormButton>

            {/* Start tracking button */}
            <StartTrackingButton
              setting="mtr"
              type="button"
              disabled={hasPaymentError || isFetching}
              onClick={trackingBtnHandler}
            >
              {isFetching ? (
                <SpinningStyleWrapper>
                  <SpinningIcon>
                    <MaterialIcon name="Rotate" />
                  </SpinningIcon>
                </SpinningStyleWrapper>
              ) : (
                <Trans
                  i18nKey="ENTERPRISE_SUMMARY_START_TRACKING_BUTTON"
                  values={{
                    price: `${Number(
                      selectPackage.selectedPackage?.summary?.total
                    ).toFixed(2)}`,
                  }}
                  components={{ bold: <strong /> }}
                />
              )}
            </StartTrackingButton>
          </EnterpriseSummaryButtonWrapper>
        </SummaryBox>

        {/* Cancel Modal */}
        {/* 2. Caster release cancel modal */}
        {releaseTrack.release_id ? (
          <ImportReleaseCancelModal
            show={showCancel}
            setShowReleaseCancelModal={setShowCancel}
          />
        ) : (
          /* 1. Default cancel modal */
          <PurchaseCancelModal
            show={showCancel}
            setShowCancel={setShowCancel}
          />
        )}

        {/* Success Modal */}
        <PurchaseSuccessModal
          show={showSuccess}
          setShowSuccess={setShowSuccess}
        />

        {/* Delete Modal */}
        {/* 1. Default Delete Song Modal */}
        <SummaryDeleteSongModal
          show={showDeleteSong}
          setShowCancel={setShowDeleteSong}
          songId={songId}
          numberOfTracks={tracks.length}
          selectedPackage={selectPackage.selectedPackage}
        />

        {/* 2. Caster Release Delete Song Modal */}
        <ImportReleaseSummaryDeleteModal
          show={showDeleteSongRelease}
          setShowCancel={setShowDeleteSongRelease}
          songId={songId}
          numberOfTracks={tracks.length}
          selectedPackage={selectPackage.selectedPackage}
        />
      </Container>
    </PurchaseWrapper>
  );
};

export default SummaryEnterprise;
