import { getSessionUser } from 'src/features/Auth';
import { utcToZonedTime, format } from 'date-fns-tz';
import { Song } from 'src/features/Dashboard';
import { AddTrackObj } from 'src/features/Purchase/MultiAsset';

// Tooltip email max length
const TOOL_TIP_EMAIL_MAX_LENGTH = 37;

// Format to short
export const formatToShort = (num: number) => {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return num;
};

// Make first letter upper case
export const makeFirstLetterUpperCase = (text: string) => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
};

const negativeOrPositiveSign = (
  isNegative: boolean,
  addNegativeOrPositiveSign: boolean
) => {
  if (addNegativeOrPositiveSign) {
    return isNegative ? '- ' : '+ ';
  }

  return '';
};

// Format number above ten thousand
export const formatNumberAboveTenThousand = (
  number: number,
  addNegativeOrPositiveSign = false
) => {
  const isNegative = number < 0;
  const absNumber = Math.abs(number);
  const sign = negativeOrPositiveSign(isNegative, addNegativeOrPositiveSign);

  if (absNumber >= 10000) {
    // Convert to K format
    const suffixes = ['', 'k', 'M', 'B', 'T']; // Additional suffixes for larger numbers if needed
    const suffixNum = Math.floor(`${absNumber}`.length / 3);
    let shortNumber = parseFloat(
      (suffixNum !== 0 ? absNumber / 1000 ** suffixNum : absNumber).toPrecision(
        2
      )
    );
    if (shortNumber % 1 !== 0) {
      shortNumber = parseFloat(shortNumber.toFixed(1));
    }
    return sign + shortNumber + suffixes[suffixNum];
  }
  return (absNumber > 0 ? sign : '') + absNumber;
};

// Format amount without rounding
export const formatAmountWithoutRoundUp = (
  amount: string | number | undefined
): number => {
  if (!amount) return 0;
  const numericAmount =
    typeof amount === 'string' ? parseFloat(amount) : amount;
  const roundedAmount = Math.floor(numericAmount * 100) / 100;
  return parseFloat(roundedAmount.toFixed(2));
};

// Format text for sentry
export const formatTextForSentryLog = (description: string) => {
  const { company_id, username } = getSessionUser();
  return `[MTR] ${description} CompanyId: ${company_id} UserName: ${username}`;
};

// Convert date to UTC
export const convertDateToUTC = (regularDate: Date) => {
  return utcToZonedTime(regularDate, 'UTC');
};

// Format date for server
export const formatDateForServer = (startTrackingDate: Date) => {
  return format(convertDateToUTC(new Date(startTrackingDate)), 'yyyy-MM-dd');
};

// Convert date to local time
export const convertDateToLocalTime = (utcDate: Date) => {
  const utcTime = utcDate.getTime();
  const localTimeDate = new Date(utcTime - utcDate.getTimezoneOffset() * 60000);
  return localTimeDate;
};

// Format title with version
export const formatTitleWithVersion = (song?: Song) => {
  if (song?.version) {
    return `${song?.title} (${song?.version})`;
  }
  return song?.title;
};

// Format title and artist with version
export const formatTitleAndArtistWithVersion = (
  track: AddTrackObj,
  displayArtist?: boolean,
  isEnterpriseProfile?: boolean
) => {
  if (track?.file?.name) {
    return `${track?.file?.name}`;
  }

  if (isEnterpriseProfile && track?.S3File?.filename) {
    return `${track?.S3File?.filename}`;
  }

  if (track?.releaseTrack?.version && displayArtist) {
    return `${track.artist} - ${track.title} (${track?.releaseTrack?.version})`;
  }

  if (track?.releaseTrack?.version) {
    return `${track.title} (${track?.releaseTrack?.version})`;
  }

  return `${track.artist} - ${track.title}`;
};

// Format tooltip email to short
export const formatEmailToShort = (fullEmail: string) => {
  if (fullEmail !== undefined && fullEmail.length > TOOL_TIP_EMAIL_MAX_LENGTH) {
    return `${fullEmail.substring(0, 38)}...`;
  }
  return fullEmail;
};
