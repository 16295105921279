export const rolesData = [
  {
    index: 1,
    id: 'artist',
    description: 'Artist',
  },
  {
    index: 2,
    id: 'promoter',
    description: 'Promoter',
  },
  {
    index: 3,
    id: 'label',
    description: 'Label',
  },
  {
    index: 4,
    id: 'major_label',
    description: 'Major label',
  },
  {
    index: 5,
    id: 'other',
    description: 'Other',
  },
];
