import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '@dsny/dsny-component-library';
import { useNavigate } from 'react-router-dom';

import theme from 'src/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import KeepTrackingScenarios from 'src/features/Common/KeepTrackingScenarios';
import { getPackageList } from './SelectPackage.thunks';
import { PackageObj, selectPlan } from './SelectPackage.slice';
import {
  PurchaseWrapper,
  FormBox,
  Container,
  StepsWrapper,
  ClickableStep,
  MainFlexButtonWrapper,
  FormButton,
} from '../Purchase.styles';
import { ChargedNote } from './SelectPackage.styles';
import { PurchaseCancelModal } from '../PurchaseCancelModal';
import { getCurrentPaymentMethod, updateSaveCardFlag } from '../AddPayment';
import { AddTrackList } from '../MultiAsset/MultiAsset.slice';
import RegularPackages from './PromoPackages/RegularPackages';
import PromoPackages from './PromoPackages/PromoPackages';
import { ImportReleaseCancelModal } from '../MultiAsset/ImportRelease/ImportReleaseCancelModal';

const SelectPackage: React.FC = () => {
  const { t } = useTranslation();
  const [showCancel, setShowCancel] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { packages } = useSelector((state: RootState) => state.selectPackage);
  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const { enterpriseProfile } = useSelector(
    (state: RootState) => state.dashboard
  );

  const { currentPaymentMethod } = useSelector(
    (state: RootState) => state.addPayment
  );

  const { tracks, releaseTrack }: AddTrackList = useSelector(
    (state: RootState) => state.multiAsset
  );

  useEffect(() => {
    if (tracks.length) {
      dispatch(getPackageList(tracks.length));
      dispatch(getCurrentPaymentMethod(false));
    } else if (selectedSong) {
      dispatch(getPackageList(1));
      dispatch(getCurrentPaymentMethod(false));
    }

    if (!tracks.length && !selectedSong) {
      navigate('/payment/multiasset');
    }
  }, [tracks]);

  useEffect(() => {
    if (enterpriseProfile.isEnterpriseProfile) {
      navigate(
        `/dashboard?songId=${selectedSong?.song_id}&openExtendModal=true`
      );
    }
  }, [enterpriseProfile]);

  useEffect(() => {
    if (currentPaymentMethod?.id) {
      dispatch(updateSaveCardFlag(true));
    }
  }, [currentPaymentMethod]);

  // Getting discount percentage
  const discountPercentage = (discount: number) => {
    return discount * 100;
  };

  const getQuantity = () => {
    return selectedSong && tracks.length === 0 ? 1 : tracks.length;
  };

  // Handling selected plan
  const selectPlanSubmit = (selectedPackage: PackageObj | undefined) => {
    dispatch(
      selectPlan({
        ...selectedPackage,
        releaseId: releaseTrack?.release_id || '',
      })
    );
    navigate('/payment/addpayment');
  };

  // Rendering breadcrumb
  const breadCrumb = () => {
    if (tracks.length > 0) {
      return (
        <StepsWrapper>
          <ClickableStep
            $isClickable
            onClick={() => navigate('/payment/multiasset')}
          >
            <Icon
              name="ArrowLeft"
              style={{
                width: '14px',
                height: '14px',
                marginRight: '4px',
                color: theme.colors.neutralW30,
              }}
            />
            {t('SIGNUP_STEP_TWO')}
          </ClickableStep>
          &nbsp;{t('SIGNUP_OF_3_STEP')}
        </StepsWrapper>
      );
    }
    return (
      <StepsWrapper>
        <ClickableStep>{t('SIGNUP_STEP_ONE')}</ClickableStep>&nbsp;
        {t('SIGNUP_OF_2_STEP')}
      </StepsWrapper>
    );
  };

  return (
    <PurchaseWrapper>
      <Container>
        <FormBox>
          {/* Breadcrumb */}
          {breadCrumb()}

          {/* Title and description based on 4 scenarios */}
          <KeepTrackingScenarios />

          {/* Packages */}
          {packages[0]?.promotion ? (
            <PromoPackages
              packages={packages}
              discountPercentage={discountPercentage}
              getQuantity={getQuantity}
              selectPlanSubmit={selectPlanSubmit}
            />
          ) : (
            <RegularPackages
              packages={packages}
              discountPercentage={discountPercentage}
              getQuantity={getQuantity}
              selectPlanSubmit={selectPlanSubmit}
            />
          )}
          {/* Charge Note */}
          <ChargedNote>
            <Trans
              i18nKey="MULTI_ASSET_CHARGE_NOTE"
              components={{ bold: <strong /> }}
            />
          </ChargedNote>
          {/* Cancel Button */}
          <MainFlexButtonWrapper>
            <FormButton
              setting="mtr"
              variant="tertiary"
              fontSize="14px"
              type="button"
              onClick={() => setShowCancel(true)}
              style={{
                fontFamily: theme.fonts.primary,
                height: '36px',
                borderRadius: '5px',
              }}
            >
              {t('PURCHASE_CANCEL_BUTTON')}
            </FormButton>
          </MainFlexButtonWrapper>
        </FormBox>

        {/* Modal */}
        {/* 1. Caster flow */}
        {releaseTrack.release_id ? (
          <ImportReleaseCancelModal
            show={showCancel}
            setShowReleaseCancelModal={setShowCancel}
          />
        ) : (
          /* 2. Default flow */
          <PurchaseCancelModal
            show={showCancel}
            setShowCancel={setShowCancel}
          />
        )}
      </Container>
    </PurchaseWrapper>
  );
};

export default SelectPackage;
