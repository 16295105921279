/* eslint-disable prettier/prettier */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/services/network';
import { downloadCsvFile } from 'src/utils/DownloadFile';

export interface getSongRequest {
  songId?: string;
  period?: number;
  trackingPeriodId?: number;
}

// Setting "Show all dates" for date range list
export const SHOW_ALL_DATES = '-1';

export const getSongs = createAsyncThunk('GET_SONGS', async () => {
  return axios.get('/subscriptions/mtr/songs/list').then((response) => {
    return response.data;
  });
});

export const getSongOverview = createAsyncThunk(
  'GET_SONG_OVERVIEW',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/${payload.songId}/overview/${payload.period}?tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        return response.data;
      });
  }
);

export const getSongOverviewCompare = createAsyncThunk(
  'GET_SONG_OVERVIEW_COMPARE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/compare/${payload.songId}/overview/${payload.period}?tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        return response.data;
      });
  }
);

export const getSongDaily = createAsyncThunk(
  'GET_SONG_DAILY',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/${payload.songId}/daily/${payload.period}?tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        return response.data;
      });
  }
);

export const getSongDailyCompare = createAsyncThunk(
  'GET_SONG_DAILY_COMPARE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/compare/${payload.songId}/daily/${payload.period}?tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        return response.data;
      });
  }
);

export const getSongHeatmap = createAsyncThunk(
  'GET_SONG_HEATMAP',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/${payload.songId}/heatmap/${payload.period}?tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        return response.data;
      });
  }
);

export const getSongHeatmapCompare = createAsyncThunk(
  'GET_SONG_HEATMAP_COMPARE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/compare/${payload.songId}/heatmap/${payload.period}?tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        return response.data;
      });
  }
);

export const getStations = createAsyncThunk(
  'GET_STATIONS',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/${payload.songId}/stations/${payload.period}?tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        return response.data;
      });
  }
);

export const getStationsCompare = createAsyncThunk(
  'GET_STATIONS_COMPARE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/compare/${payload.songId}/stations/${payload.period}?tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        return response.data;
      });
  }
);

export const getCities = createAsyncThunk(
  'GET_CITIES',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/${payload.songId}/cities/${payload.period}?tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        return response.data;
      });
  }
);

export const getCitiesCompare = createAsyncThunk(
  'GET_CITIES_COMPARE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/compare/${payload.songId}/cities/${payload.period}?tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        return response.data;
      });
  }
);

export const getAllDataFile = createAsyncThunk(
  'GET_All_DATA_FILE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/reporting/songs/${payload.songId}/airplay?format=csv&tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        downloadCsvFile(response);
      });
  }
);

export const getSongDailyFile = createAsyncThunk(
  'GET_SONG_DAILY_FILE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/${payload.songId}/daily/${payload.period}?format=csv&tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        downloadCsvFile(response);
      });
  }
);

export const getSongDailyFileCompare = createAsyncThunk(
  'GET_SONG_DAILY_FILE_COMPARE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/compare/${payload.songId}/daily/${payload.period}?format=csv&tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        downloadCsvFile(response);
      });
  }
);

export const getStationsFile = createAsyncThunk(
  'GET_STATIONS_FILE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/${payload.songId}/stations/${payload.period}?format=csv&tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        downloadCsvFile(response);
      });
  }
);

export const getStationsFileCompare = createAsyncThunk(
  'GET_STATIONS_FILE_COMPARE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/compare/${payload.songId}/stations/${payload.period}?format=csv&tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        downloadCsvFile(response);
      });
  }
);

export const getCitiesFile = createAsyncThunk(
  'GET_CITIES_FILE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/${payload.songId}/cities/${payload.period}?format=csv&tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        downloadCsvFile(response);
      });
  }
);

export const getCitiesFileCompare = createAsyncThunk(
  'GET_CITIES_FILE_COMPARE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/compare/${payload.songId}/cities/${payload.period}?format=csv&tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        downloadCsvFile(response);
      });
  }
);

export const getSongHeatmapFile = createAsyncThunk(
  'GET_SONG_HEATMAP_FILE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/${payload.songId}/heatmap/${payload.period}?format=csv&tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        downloadCsvFile(response);
      });
  }
);

export const getSongHeatmapFileCompare = createAsyncThunk(
  'GET_SONG_HEATMAP_FILE_COMPARE',
  async (payload: getSongRequest) => {
    return axios
      .get(`/subscriptions/mtr/songs/compare/${payload.songId}/heatmap/${payload.period}?format=csv&tracking_period_id=${payload.trackingPeriodId}`)
      .then((response) => {
        downloadCsvFile(response);
      });
  }
);

export const getPromotions = createAsyncThunk(
  'GET_PROMOTIONS',
  async () => {
    return axios
      .get(`/subscriptions/mtr/promotions`)
      .then((response) => response.data)
      .then((data) => {
        return data;
      });
  }
);

export const getEnterpriseProfile = createAsyncThunk(
  'GET_ENTERPRISE_PROFILE',
  async () => {
    return axios
      .get(`/subscriptions/mtr/enterprise/plan`)
      .then((response) => response.data)
      .then((data) => {
        return data;
      });
  }
);
