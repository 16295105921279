import { Song, TrackingStatusEnum } from 'src/features/Dashboard';
import { AddTrackObj } from 'src/features/Purchase/MultiAsset';

export const isCasterReleaseSong = (track: AddTrackObj): boolean => {
  if (track) {
    return track?.file === undefined;
  }

  return false;
};

/* Scenario 0 - first purchase or subsequent ones */
export const isFirstPurchase = (tracks: AddTrackObj[]): boolean => {
  return tracks.length > 0;
};

/* Scenario 1 (extend) - active, warning and day 1 of expiration */
export const isExtendDayOne = (song?: Song): boolean => {
  return !!song && song.selected_tracking_period.remainingDays >= 0;
};

/* Scenario 2 (extend) -  expired within 90 day period - starting day 2 of expiration until day 90  */
export const isExtendWithinNinetyDays = (song?: Song): boolean => {
  return (
    !!song &&
    song.selected_tracking_period.status === TrackingStatusEnum.EXPIRED
  );
};

/* Scenario 3 (renew) -  expired past 90 day period - starting day 91  */
export const isRenewPastNinetyDays = (song?: Song): boolean => {
  return (
    !!song &&
    song.selected_tracking_period.status === TrackingStatusEnum.PAST_EXPIRED
  );
};
