import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/services/network';
import { trackEvent } from 'src/utils/segmentService';

declare type Track = {
  track_id: string | number | null;
  artist: string | undefined;
  title: string | undefined;
  version: string | undefined;
  start_date: string;
  upload_s3_bucket: string;
  upload_s3_key: string;
  src_filename: string;
};

declare type S3RequestData = {
  release_id?: string;
  identifier: string;
  tracks: Track[];
};

declare type enterpriseS3RequestData = {
  release_id?: string;
  identifier: string;
  items: Track[];
};

export const uploadFilesToPlaylist = createAsyncThunk(
  'UPLOAD_FILE_TO_PLAYLIST',
  async (s3RequestData: S3RequestData) => {
    return axios.post('/assets/mtr/songs', s3RequestData).then((response) => {
      trackEvent('Assets Uploaded', {
        asset_count: s3RequestData.tracks?.length.toString() || '',
        release_id: s3RequestData.release_id || '',
      });
      return response.data;
    });
  }
);

export const enterpriseUploadFilesToPlaylist = createAsyncThunk(
  'ENTERPRISE_UPLOAD_FILE_TO_PLAYLIST',
  async (s3RequestData: enterpriseS3RequestData) => {
    return axios
      .post('/subscriptions/mtr/enterprise/songs', s3RequestData)
      .then((response) => {
        trackEvent('Assets Uploaded', {
          asset_count: s3RequestData.items?.length.toString() || '',
          release_id: s3RequestData.release_id || '',
        });
        return response.data;
      });
  }
);

export const deleteTrack = createAsyncThunk(
  'DELETE_TRACK',
  async (ids: string[]) => {
    return axios
      .delete('/assets/mtr/songs', {
        data: { song_ids: ids },
      })
      .then((response) => {
        return response.data;
      });
  }
);

export const getReleaseTracks = createAsyncThunk(
  'GET_RELEASE_TRACKS',
  async (releaseId: string, { rejectWithValue }) => {
    return axios
      .get(`/subscriptions/mtr/releases/${releaseId}/tracks`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.status);
      });
  }
);

export default uploadFilesToPlaylist;
