import styled from 'styled-components';
import { Button } from '@dsny/dsny-component-library';
import { device } from 'src/styles/global';

export interface Props {
  hasComparisonOn?: boolean;
}

export const AddNewTrackWrapper = styled.div<Props>`
  min-width: 162px;
  align-self: center;
  margin-bottom: ${({ hasComparisonOn }) => (hasComparisonOn ? '24px' : '0px')};

  @media ${device.mobile} {
    width: 100%;
    margin-bottom: 0px;
  }

  @media ${device.tablet} {
    width: 100%;
    margin-bottom: 0px;
  }

  @media ${device.ipad} {
    width: 100%;
  }
`;

export const AddNewTrackButton = styled(Button)`
  width: 100%;
  svg {
    width: 18px;
    height: 18px;
    padding-bottom: 2px;
  }
`;
