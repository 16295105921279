import { Tonality } from '@dsny/dsny-component-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { countriesData } from 'src/consts/CountriesData';
import {
  ComboboxInputStyles,
  ComboboxOptionWrapper,
  ComboboxStyles,
  ComboboxWrapperCountry,
  ComboboxPopoverStylesCountryState,
  InlineMessage,
  ItemWrapper,
  InputLabelStyles,
} from './SignupFields.styles';

export type CountryData = {
  index: number | undefined;
  id: string;
  description: string;
};

interface Props {
  onSelect: (item?: CountryData) => void;
  ref?: null;
  error: boolean;
}

const SignupCountry: React.FC<Props> = ({ error, onSelect }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>('');

  const openCombobox = () => {
    setOpen(true);
  };

  const closeCombobox = () => {
    setOpen(false);
  };

  // Handling country selection
  const onChange = (option: string) => {
    setValue(option);
    onSelect(countriesData.find((item) => item.description === option));
  };

  return (
    <>
      {/* Country */}
      <ComboboxWrapperCountry>
        <Tonality.Combobox
          label={t('SIGNUP_COUNTRY_LABEL')}
          isOpen={open}
          onClick={openCombobox}
          onClose={closeCombobox}
          onChange={onChange}
          value={value}
          setting="mtr"
          style={{ ...ComboboxStyles }}
        >
          <Tonality.ComboboxInput
            placeholder={t('SIGNUP_SELECT_PLACEHOLDER')}
            required
            hasError={error}
            style={{ ...ComboboxInputStyles }}
            inputLabelStyles={{ ...InputLabelStyles }}
          />
          <Tonality.ComboboxPopover
            style={{ ...ComboboxPopoverStylesCountryState }}
          >
            <Tonality.ComboboxList
              style={{ paddingTop: '16px', paddingBottom: '16px' }}
            >
              {countriesData?.map((country) => (
                <ComboboxOptionWrapper
                  key={country.id}
                  value={country.description}
                  isSelected={country.description === value}
                  style={{ paddingLeft: '16px' }}
                >
                  <ItemWrapper>{country.description}</ItemWrapper>
                </ComboboxOptionWrapper>
              ))}
            </Tonality.ComboboxList>
          </Tonality.ComboboxPopover>

          {/* If not selected apply inline message  */}
          {error && (
            <InlineMessage $inlineMessage>
              {t('SIGNUP_COUNTRY_ERROR_MESSAGE')}
            </InlineMessage>
          )}
        </Tonality.Combobox>
      </ComboboxWrapperCountry>
    </>
  );
};

export default SignupCountry;
