/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { BASE_SITE_TERMS_OF_SERVICE } from 'src/utils/Urls';
import {
  Banner,
  Checkbox,
  Icon,
  MTRFullLogo,
  MaterialIcon,
  SpinningIcon,
  TextField,
} from '@dsny/dsny-component-library';
import {
  passwordRegex,
  hasNumbers,
  hasMinimunSize,
  hasSpecialCharacters,
  emailRegex,
} from 'src/utils/RegexPatterns';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  sendSignupEmail,
  checkExistentUser,
} from 'src/features/Auth/Auth.thunks';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import theme from 'src/styles/theme';
import { clearSignupResponse } from 'src/features/Auth/Auth.slice';
import { inputHandler } from 'src/utils/InputHandler';
import {
  FormBox,
  Title,
  Container,
  RequirementWrapper,
  Text,
  LogoMTRWrapper,
  IconWrapper,
  StepsWrapper,
  ClickableStep,
  TermsLink,
  LinkStyle,
  HaveAccountSection,
  MessageBox,
  MessageTitle,
  MessageWrapper,
  MessageContainer,
  SigninButtonWrapper,
  SigninButton,
  FlexEndButtonWrapper,
  StepsButton,
  ErrorWrapper,
  CheckboxWrapperStepOne,
} from './Signup.styles';
import { AuthWrapper } from '../Auth.styles';
import { trackEvent } from '../../../utils/segmentService';

interface passwordVisibilityList {
  newPassword: passwordVisibilityObj;
  confirmPassword: passwordVisibilityObj;
}

interface passwordVisibilityObj {
  isVisible: boolean;
  visibilityData: passwordVisibilityData;
}

interface passwordVisibilityData {
  iconName: string;
  type: string;
}

const getVisibilityData = (isVisible: boolean) => {
  return isVisible
    ? { iconName: 'Visibility', type: 'text' }
    : { iconName: 'VisibilityOff', type: 'password' };
};

const schema = yup.object().shape({
  email: yup.string().matches(emailRegex).required(),
  newPassword: yup.string().matches(passwordRegex).required(),
  confirmPassword: yup.string().required(),
});

const EMAIL_FIELD = 'email';
const NEW_PASSWORD_FIELD = 'newPassword';
const CONFIRM_PASSWORD_FIELD = 'confirmPassword';

const SignupStepOne: React.FC = () => {
  const { t } = useTranslation();
  const [stepOne, setStepOne] = useState(true);
  const [rerender, setRerender] = useState(false);
  const [displayTips, setDisplayTips] = useState(false);
  const [termsCheckbox, setTermsCheckbox] = useState<string>('');
  const [termsCheckboxError, setTermsCheckboxError] = useState<boolean>(false);
  const { isFetching, signUp } = useSelector((state: RootState) => state.auth);
  const { email, password, isValidUser, hasResponseError } = signUp.stepOne;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const [passwordMatchErrorMessage, setPasswordMatchErrorMessage] =
    useState('');
  const [passwordVisibleList, setPasswordVisibleList] = useState({
    newPassword: {
      isVisible: false,
      visibilityData: getVisibilityData(false),
    },
    confirmPassword: {
      isVisible: false,
      visibilityData: getVisibilityData(false),
    },
  } as passwordVisibilityList);

  // Form validation step one
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver<any>(schema),
    shouldUnregister: true,
  });

  // Handling new password regex pattern
  const validatePassword = (pattern: RegExp) => {
    return pattern.test(getValues(NEW_PASSWORD_FIELD));
  };

  // Handling password tips
  const showTips = () => {
    if (
      validatePassword(hasMinimunSize) &&
      validatePassword(hasNumbers) &&
      validatePassword(hasSpecialCharacters)
    ) {
      setDisplayTips(false);
    }
  };

  // Handling password error messages
  const setConfirmPasswordMessage = (passwordDontMatch: boolean) => {
    if (errors?.confirmPassword) {
      return t('SIGNUP_ERROR_CONFIRM_PASSWORD');
    }
    if (passwordDontMatch) {
      return t('SIGNUP_ERROR_NOT_MATCH');
    }
    return '';
  };

  // Handling password visibility
  const handlePasswordVisibility = (field: string) => {
    const passwordVisibleListTemp = passwordVisibleList;
    const isVisible =
      !passwordVisibleListTemp[field as keyof passwordVisibilityList].isVisible;

    passwordVisibleListTemp[field as keyof passwordVisibilityList].isVisible =
      isVisible;

    passwordVisibleListTemp[
      field as keyof passwordVisibilityList
    ].visibilityData = getVisibilityData(isVisible);

    setPasswordVisibleList({ ...passwordVisibleListTemp });
  };

  // Handling terms checkbox
  const handleTermsCheckbox = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const checked = target.value;
    setTermsCheckbox(checked ? '' : 'termsCheckValue');
  };

  // Handling step one validation
  const handleValidation = () => {
    // Extract values for better readability
    const newPassword = getValues(NEW_PASSWORD_FIELD);
    const confirmPassword = getValues(CONFIRM_PASSWORD_FIELD);
    const emailValue = getValues(EMAIL_FIELD);

    const areFieldsFilled = newPassword && confirmPassword && emailValue;
    const doPasswordsMatch = newPassword === confirmPassword;
    const hasPasswordError = !!errors.newpassword;
    const areTermsAccepted = termsCheckbox !== '';

    // Combine all validation checks
    const isStepOneValid =
      areFieldsFilled &&
      doPasswordsMatch &&
      !hasPasswordError &&
      areTermsAccepted;

    setStepOne(!isStepOneValid);
  };

  // Handling onSubmit step one
  const onSubmit = ({ email: username }: any) => {
    if (termsCheckbox === '') setTermsCheckboxError(true);
    if (stepOne) return;
    dispatch(checkExistentUser(username));
  };

  // Step one error banner
  const errorTermsAndConditions = {
    title: t('SIGNUP_ERROR_TERMS_AND_CONDITIONS'),
    description: t('SIGNUP_ERROR_TERMS_AND_CONDITIONS_DESC'),
  };

  useEffect(() => {
    handleValidation();
  }, [termsCheckbox]);

  useEffect(() => {
    if (isValidUser) {
      dispatch(
        sendSignupEmail({
          email: getValues(EMAIL_FIELD),
          password: getValues(NEW_PASSWORD_FIELD),
        })
      ).then(() => {
        trackEvent('Send Email Verification', { is_resend: 'false' }, () => {
          const searchParams = new URLSearchParams(location.search);
          navigate(`/auth/signupsteptwo?${searchParams.toString()}`);
        });
      });
    }
  }, [isValidUser]);

  useEffect(() => {
    setValue(EMAIL_FIELD, email);
    setValue(NEW_PASSWORD_FIELD, password);
    setValue(CONFIRM_PASSWORD_FIELD, password);
    if (email) setTermsCheckbox('termsCheckValue');
  }, [signUp]);

  return (
    <AuthWrapper>
      <LogoMTRWrapper>
        <MTRFullLogo />
      </LogoMTRWrapper>

      {/* Step 1 */}
      {!hasResponseError && (
        <Container>
          <FormBox onSubmit={handleSubmit(onSubmit)}>
            {/* Banner message */}
            {termsCheckboxError && (
              <ErrorWrapper>
                <Banner
                  bannerContent={errorTermsAndConditions}
                  variant="error"
                />
              </ErrorWrapper>
            )}

            <StepsWrapper>
              <ClickableStep>{t('SIGNUP_STEP_ONE')}</ClickableStep>&nbsp;
              {t('SIGNUP_OF_3_STEP')}
            </StepsWrapper>
            <Title>{t('SIGNUP_STEP_ONE_TITLE')}</Title>
            <TextField
              id="email"
              setting="mtr"
              labelText={t('SIGNUP_USERNAME')}
              type="text"
              required
              {...register(EMAIL_FIELD)}
              inputRef={null}
              error={errors.email && t('SIGNUP_ERROR_USERNAME')}
              onBlur={(e: Event) => {
                inputHandler(setValue, trigger, e);
                handleValidation();
              }}
              onChange={(e: ChangeEvent) => {
                inputHandler(setValue, trigger, e);
                handleValidation();
              }}
              onFocus={() => setRerender(!rerender)}
              wrapperStyles={{
                paddingRight: '0px',
                paddingBottom: '32px',
                marginBottom: '0px',
              }}
              inputStyle={{
                height: '44px',
                borderRadius: '5px',
                borderColor: errors.email && theme.colors.error50,
              }}
              labelStyle={{
                fontSize: '14px',
                color: errors.email && theme.colors.error50,
              }}
              errorStyle={{
                marginTop: '8px',
                color: theme.colors.error50,
              }}
              ref={null}
            />

            <TextField
              id="newPassword"
              setting="mtr"
              labelText={t('SIGNUP_PASSWORD')}
              type={passwordVisibleList.newPassword.visibilityData.type}
              required
              {...register(NEW_PASSWORD_FIELD)}
              error={
                errors.newPassword && !getValues(NEW_PASSWORD_FIELD)?.length
                  ? t('SIGNUP_ERROR_PASSWORD')
                  : ''
              }
              onBlur={(e: Event) => {
                inputHandler(setValue, trigger, e);
                handleValidation();
                showTips();
                setPasswordMatchErrorMessage(
                  setConfirmPasswordMessage(
                    getValues(CONFIRM_PASSWORD_FIELD) &&
                      getValues(NEW_PASSWORD_FIELD) !==
                        getValues(CONFIRM_PASSWORD_FIELD)
                  )
                );
              }}
              onChange={(e: ChangeEvent) => {
                inputHandler(setValue, trigger, e);
                handleValidation();
                setDisplayTips(!!getValues(NEW_PASSWORD_FIELD));
                setPasswordMatchErrorMessage(
                  setConfirmPasswordMessage(
                    getValues(CONFIRM_PASSWORD_FIELD) &&
                      getValues(CONFIRM_PASSWORD_FIELD).length >=
                        getValues(NEW_PASSWORD_FIELD).length &&
                      getValues(NEW_PASSWORD_FIELD) !==
                        getValues(CONFIRM_PASSWORD_FIELD)
                  )
                );
              }}
              onFocus={() => setDisplayTips(!!getValues(NEW_PASSWORD_FIELD))}
              onClick={() => handlePasswordVisibility(NEW_PASSWORD_FIELD)}
              wrapperStyles={{
                paddingRight: '0px',
                paddingBottom: '0px',
                marginBottom: displayTips ? '0px' : '32px',
              }}
              inputStyle={{
                height: '44px',
                borderRadius: '5px',
                borderColor: errors.newPassword && theme.colors.error50,
              }}
              labelStyle={{
                fontSize: '14px',
                color: errors.newPassword && theme.colors.error50,
              }}
              errorStyle={{ marginTop: '8px', color: theme.colors.error50 }}
              tailingIcon={
                passwordVisibleList.newPassword.visibilityData.iconName
              }
              isTailingClickable
              tailingStyle={{
                paddingTop: displayTips
                  ? '33px'
                  : errors?.newPassword
                  ? '7px'
                  : '33px',
                paddingRight: '10px',
              }}
              ref={null}
            />

            {displayTips && (
              <RequirementWrapper>
                <p>{t('SIGNUP_TIPS')}</p>
                <Text $isInvalid={!validatePassword(hasMinimunSize)}>
                  <IconWrapper $isInvalid={!validatePassword(hasMinimunSize)}>
                    <Icon
                      name={!validatePassword(hasMinimunSize) ? 'X' : 'Check'}
                      style={{
                        width: '12px',
                        height: 'auto',
                        marginRight: '8px',
                      }}
                    />
                  </IconWrapper>
                  {t('SIGNUP_AT_LEAST_SEVEN_CHARACTERS')}
                </Text>
                <Text $isInvalid={!validatePassword(hasNumbers)}>
                  <IconWrapper $isInvalid={!validatePassword(hasNumbers)}>
                    <Icon
                      name={!validatePassword(hasNumbers) ? 'X' : 'Check'}
                      style={{
                        width: '12px',
                        height: 'auto',
                        marginRight: '8px',
                      }}
                    />
                  </IconWrapper>
                  {t('SIGNUP_AT_LEATS_ONE_NUMBER')}
                </Text>
                <Text $isInvalid={!validatePassword(hasSpecialCharacters)}>
                  <IconWrapper
                    $isInvalid={!validatePassword(hasSpecialCharacters)}
                  >
                    <Icon
                      name={
                        !validatePassword(hasSpecialCharacters) ? 'X' : 'Check'
                      }
                      style={{
                        width: '12px',
                        height: 'auto',
                        marginRight: '8px',
                      }}
                    />
                  </IconWrapper>
                  {t('SIGNUP_AT_LEAST_ONE_SPECIAL_CHARACTER')}
                </Text>
              </RequirementWrapper>
            )}

            <TextField
              id="confirmPassword"
              setting="mtr"
              labelText={t('SIGNUP_CONFIRM_PASSWORD')}
              type={passwordVisibleList.confirmPassword.visibilityData.type}
              required
              {...register(CONFIRM_PASSWORD_FIELD)}
              error={
                errors?.confirmPassword
                  ? t('SIGNUP_ERROR_CONFIRM_PASSWORD')
                  : passwordMatchErrorMessage
              }
              onBlur={(e: Event) => {
                inputHandler(setValue, trigger, e);
                handleValidation();
                setPasswordMatchErrorMessage(
                  setConfirmPasswordMessage(
                    getValues(CONFIRM_PASSWORD_FIELD) &&
                      getValues(NEW_PASSWORD_FIELD) !==
                        getValues(CONFIRM_PASSWORD_FIELD)
                  )
                );
              }}
              onChange={(e: ChangeEvent) => {
                inputHandler(setValue, trigger, e);
                handleValidation();
                setPasswordMatchErrorMessage(
                  setConfirmPasswordMessage(
                    getValues(CONFIRM_PASSWORD_FIELD) &&
                      getValues(CONFIRM_PASSWORD_FIELD).length >=
                        getValues(NEW_PASSWORD_FIELD).length &&
                      getValues(NEW_PASSWORD_FIELD) !==
                        getValues(CONFIRM_PASSWORD_FIELD)
                  )
                );
              }}
              onClick={() => handlePasswordVisibility(CONFIRM_PASSWORD_FIELD)}
              wrapperStyles={{
                paddingRight: '0px',
                paddingBottom: '0px',
                marginBottom: '16px',
              }}
              inputStyle={{
                height: '44px',
                borderRadius: '5px',
                borderColor:
                  (errors?.confirmPassword || passwordMatchErrorMessage) &&
                  theme.colors.error50,
              }}
              labelStyle={{
                fontSize: '14px',
                color:
                  (errors?.confirmPassword || passwordMatchErrorMessage) &&
                  theme.colors.error50,
              }}
              errorStyle={{ marginTop: '8px', color: theme.colors.error50 }}
              tailingIcon={
                passwordVisibleList.confirmPassword.visibilityData.iconName
              }
              isTailingClickable
              tailingStyle={{
                paddingTop:
                  errors?.confirmPassword || passwordMatchErrorMessage
                    ? '7px'
                    : '33px',
                paddingRight: '10px',
              }}
              ref={null}
            />

            <CheckboxWrapperStepOne>
              <Checkbox
                id="termsCheckValue"
                name="termsCheckValue"
                color={theme.colors.green}
                onChange={handleTermsCheckbox}
                value={termsCheckbox}
                checked={!!termsCheckbox}
                style={{
                  borderColor: theme.colors.neutralW70,
                  marginRight: '8px',
                  marginBottom: '4px',
                }}
              />
              <span>
                {t('SIGNUP_ACCEPT_TERMS')}{' '}
                <TermsLink
                  href={BASE_SITE_TERMS_OF_SERVICE}
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  {t('SIGNUP_TERMS_AND_CONDITIONS_LINK')}
                </TermsLink>
              </span>
            </CheckboxWrapperStepOne>

            <FlexEndButtonWrapper>
              <StepsButton
                setting="mtr"
                fontSize="14px"
                type="submit"
                style={{
                  fontFamily: theme.fonts.primary,
                  height: '36px',
                  borderRadius: '5px',
                  paddingTop: isFetching ? '14px' : '',
                }}
              >
                {isFetching ? (
                  <SpinningIcon>
                    <MaterialIcon name="Rotate" />
                  </SpinningIcon>
                ) : (
                  t('SIGNUP_CTA_NEXT_BUTTON')
                )}
              </StepsButton>
            </FlexEndButtonWrapper>

            <HaveAccountSection>
              {t('SIGNUP_HAVE_ACCOUNT')}&nbsp;
              <LinkStyle to="/auth/signin">
                {t('SIGNUP_HAVE_ACCOUNT_LINK')}
              </LinkStyle>
            </HaveAccountSection>
          </FormBox>
        </Container>
      )}

      {/* Existing account in Caster or MTR */}
      {hasResponseError !== 0 && (
        <MessageContainer>
          <MessageBox>
            <MessageTitle>
              {hasResponseError === 1
                ? t('SIGNUP_ACCOUNT_FOUND')
                : t('SIGNUP_ACCOUNT_FOUND_MTR')}
            </MessageTitle>
            <MessageWrapper>
              <p>
                {hasResponseError === 1
                  ? t('SIGNUP_ACCOUNT_FOUND_DESC')
                  : t('SIGNUP_ACCOUNT_FOUND_MTR_DESC')}
              </p>
              <SigninButtonWrapper>
                <SigninButton
                  setting="mtr"
                  fontSize="14px"
                  type="button"
                  onClick={() => {
                    dispatch(clearSignupResponse());
                    navigate('/auth/signin');
                  }}
                  style={{
                    fontFamily: theme.fonts.primary,
                    height: '36px',
                    borderRadius: '5px',
                  }}
                >
                  {t('SIGNUP_SIGNIN_BUTTON')}
                </SigninButton>
              </SigninButtonWrapper>
            </MessageWrapper>
          </MessageBox>
        </MessageContainer>
      )}
    </AuthWrapper>
  );
};

export default SignupStepOne;
