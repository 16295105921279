import { MaterialIcon } from '@dsny/dsny-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { AddNewTrackButton, AddNewTrackWrapper } from './AddNewTrack.styles';

interface AddNewTrackProps {
  hasComparisonOn: boolean;
  setShowEnterpriseLimit: (value: boolean) => void;
}

const AddNewTrack: React.FC<AddNewTrackProps> = ({
  hasComparisonOn,
  setShowEnterpriseLimit,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { enterpriseProfile } = useSelector(
    (state: RootState) => state.dashboard
  );

  // handling add new track navigation
  const handleNavigation = () => {
    if (
      enterpriseProfile.active_songs_count &&
      enterpriseProfile.songs_limit &&
      enterpriseProfile.active_songs_count >= enterpriseProfile.songs_limit
    ) {
      setShowEnterpriseLimit(true);
    } else {
      navigate('/payment/multiasset');
    }
  };

  return (
    <>
      {/* Add New Track */}
      <AddNewTrackWrapper hasComparisonOn={hasComparisonOn}>
        <AddNewTrackButton
          setting="mtr"
          fontSize="14px"
          type="button"
          onClick={() => handleNavigation()}
        >
          <MaterialIcon name="Add" />
          &nbsp;{t('DASHBOARD_ADD_NEW_TRACK_BUTTON')}
        </AddNewTrackButton>
      </AddNewTrackWrapper>
    </>
  );
};

export default AddNewTrack;
