/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Modal,
  Button,
  SpinningIcon,
  MaterialIcon,
  Banner,
} from '@dsny/dsny-component-library';
import KeepTrackingScenarios from 'src/features/Common/KeepTrackingScenarios';
import {
  isExtendDayOne,
  isExtendWithinNinetyDays,
} from 'src/utils/VerifyTrackStatus';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import {
  enterprisePurchaseExtension,
  enterprisePurchaseRenewal,
} from 'src/features/Purchase/AddPayment';
import { v4 as uuid } from 'uuid';
import {
  DesktopWrapper,
  ErrorWrapper,
  ExtendButton,
  FlexButtonWrapper,
  MobileWrapper,
  ModalBodyWrapper,
  ModalDescWrapper,
  ModalHeadingWrapper,
  SpinningStyleWrapper,
} from './EnterpriseExtendSongModal.styles';
import { EnterpriseSelectedSong } from './EnterpriseSelectedSong';

export const EnterpriseExtendSongModal = ({ showModal, setShowModal }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const { enterpriseProfile } = useSelector(
    (state: RootState) => state.dashboard
  );

  const { purchaseCheckout, isFetching, hasError } = useSelector(
    (state: RootState) => state.addPayment
  );

  // Handling extend-renew button
  const handleExtendButton = () => {
    if (enterpriseProfile.isEnterpriseProfile) {
      const identifier = uuid().replace(/-/gi, '');
      const song_id = selectedSong?.song_id || '';
      if (
        isExtendDayOne(selectedSong) ||
        isExtendWithinNinetyDays(selectedSong)
      ) {
        dispatch(enterprisePurchaseExtension({ identifier, song_id }));
      } else {
        dispatch(enterprisePurchaseRenewal({ identifier, song_id }));
      }
    }
  };

  // Getting sucessull title based on 3 scenarios
  const getSuccessfulTitle = () => {
    /* Scenario 1 (extend) - active, warning and day 1 of expiration */
    if (isExtendDayOne(selectedSong)) {
      return <>{t('ENTERPRISE_EXTEND_ACTIVE_SUCCESS_TITLE')}</>;
    }

    /* Scenario 2 (extend) -  expired within 90 day period - starting day 2 of expiration until day 90  */
    if (isExtendWithinNinetyDays(selectedSong)) {
      return <>{t('ENTERPRISE_EXTEND_EXPIRED_SUCCESS_TITLE')}</>;
    }

    /* Scenario 3 (renew) -  expired past 90 day period - starting day 91  */
    return <>{t('ENTERPRISE_RENEW_EXPIRED_SUCCESS_TITLE')}</>;
  };

  // Getting sucessull text based on 3 scenarios
  const getSuccessfulText = () => {
    /* Scenario 1 (extend) - active, warning and day 1 of expiration */
    if (isExtendDayOne(selectedSong)) {
      return (
        <>
          <Trans
            i18nKey="ENTERPRISE_EXTEND_ACTIVE_SUCCESS_TEXT"
            values={{ song: selectedSong?.title }}
          />
        </>
      );
    }

    /* Scenario 2 (extend) -  expired within 90 day period - starting day 2 of expiration until day 90  */
    if (isExtendWithinNinetyDays(selectedSong)) {
      return (
        <>
          <Trans
            i18nKey="ENTERPRISE_EXTEND_EXPIRED_SUCCESS_TEXT"
            values={{ song: selectedSong?.title }}
          />
        </>
      );
    }

    /* Scenario 3 (renew) -  expired past 90 day period - starting day 91  */
    return (
      <>
        <Trans
          i18nKey="ENTERPRISE_RENEW_EXPIRED_SUCCESS_TEXT"
          values={{ song: selectedSong?.title }}
        />
      </>
    );
  };

  // Getting extend-renew button text
  const getExtendRenewButtonText = () => {
    /* Scenario 1 (extend) - active, warning and day 1 of expiration */
    /* Scenario 2 (extend) -  expired within 90 day period - starting day 2 of expiration until day 90  */
    if (
      isExtendDayOne(selectedSong) ||
      isExtendWithinNinetyDays(selectedSong)
    ) {
      return <>{t('ENTERPRISE_EXTEND_TRACKING_MODAL_BUTTON')}</>;
    }

    /* Scenario 3 (renew) -  expired past 90 day period - starting day 91  */
    return <>{t('ENTERPRISE_RENEW_TRACKING_MODAL_BUTTON')}</>;
  };

  // Handling go to dashboard button
  const handleGoToDashboardButton = () => {
    setShowModal(false);
    window.location.reload(); // forcing state to reload after renew or extention
  };

  // Unable to complete transaction banner
  const serverErrorMessage = {
    title: t('ENTERPRISE_SUMMARY_ERROR_BANNER_TITLE'),
    description: t('ENTERPRISE_SUMMARY_ERROR_BANNER_DESC'),
  };

  return (
    <>
      {/* Desktop view */}
      <DesktopWrapper>
        <Modal
          isOpen={showModal}
          onClose={
            purchaseCheckout.isPurchaseSuccessful
              ? () => undefined
              : () => setShowModal(false)
          }
          containerStyles={{
            width: '796px',
            padding: '32px',
          }}
        >
          {/* Banner error message */}
          {hasError && (
            <ErrorWrapper>
              <Banner
                bannerContent={serverErrorMessage}
                variant="error"
                width="100%"
              />
            </ErrorWrapper>
          )}

          {/* Modal heading */}
          <ModalHeadingWrapper>
            {!purchaseCheckout.isPurchaseSuccessful
              ? t('ENTERPRISE_EXTEND_SONG_MODAL_TITLE')
              : getSuccessfulTitle()}
          </ModalHeadingWrapper>

          {/* Modal description */}
          <ModalDescWrapper>
            {!purchaseCheckout.isPurchaseSuccessful ? (
              <KeepTrackingScenarios />
            ) : (
              getSuccessfulText()
            )}
          </ModalDescWrapper>

          {/* Selected song */}
          {!purchaseCheckout.isPurchaseSuccessful && (
            <ModalBodyWrapper>
              <EnterpriseSelectedSong />
            </ModalBodyWrapper>
          )}

          <FlexButtonWrapper>
            {/* Cancel button */}
            {!purchaseCheckout.isPurchaseSuccessful && (
              <Button
                setting="mtr"
                variant="tertiary"
                type="button"
                onClick={() => setShowModal(false)}
              >
                {t('ENTERPRISE_CANCEL_MODAL_BUTTON')}
              </Button>
            )}

            {!purchaseCheckout.isPurchaseSuccessful ? (
              /* Extend-Renew button */
              <ExtendButton
                setting="mtr"
                type="button"
                onClick={() => handleExtendButton()}
              >
                {isFetching ? (
                  <SpinningStyleWrapper>
                    <SpinningIcon>
                      <MaterialIcon name="Rotate" />
                    </SpinningIcon>
                  </SpinningStyleWrapper>
                ) : (
                  getExtendRenewButtonText()
                )}
              </ExtendButton>
            ) : (
              // Go to dashboard button
              <Button
                setting="mtr"
                type="button"
                onClick={() => handleGoToDashboardButton()}
              >
                {t('ENTERPRISE_GO_TO_DASHBOARD_BUTTON')}
              </Button>
            )}
          </FlexButtonWrapper>
        </Modal>
      </DesktopWrapper>

      {/* Mobile view */}
      <MobileWrapper>
        <Modal
          isOpen={showModal}
          onClose={
            purchaseCheckout.isPurchaseSuccessful
              ? () => undefined
              : () => setShowModal(false)
          }
          containerStyles={{
            width: '100%',
            padding: '24px',
          }}
        >
          {/* Banner error message */}
          {hasError && (
            <ErrorWrapper>
              <Banner
                bannerContent={serverErrorMessage}
                variant="error"
                width="100%"
              />
            </ErrorWrapper>
          )}

          {/* Modal heading */}
          <ModalHeadingWrapper>
            {!purchaseCheckout.isPurchaseSuccessful
              ? t('ENTERPRISE_EXTEND_SONG_MODAL_TITLE')
              : getSuccessfulTitle()}
          </ModalHeadingWrapper>

          {/* Modal description */}
          <ModalDescWrapper>
            {!purchaseCheckout.isPurchaseSuccessful ? (
              <KeepTrackingScenarios />
            ) : (
              getSuccessfulText()
            )}
          </ModalDescWrapper>

          {/* Selected song */}
          {!purchaseCheckout.isPurchaseSuccessful && (
            <ModalBodyWrapper>
              <EnterpriseSelectedSong />
            </ModalBodyWrapper>
          )}

          <FlexButtonWrapper>
            {/* Cancel button */}
            {!purchaseCheckout.isPurchaseSuccessful && (
              <Button
                setting="mtr"
                variant="tertiary"
                type="button"
                onClick={() => setShowModal(false)}
              >
                {t('ENTERPRISE_CANCEL_MODAL_BUTTON')}
              </Button>
            )}

            {!purchaseCheckout.isPurchaseSuccessful ? (
              /* Extend-Renew button */
              <ExtendButton
                setting="mtr"
                type="button"
                onClick={() => handleExtendButton()}
              >
                {isFetching ? (
                  <SpinningStyleWrapper>
                    <SpinningIcon>
                      <MaterialIcon name="Rotate" />
                    </SpinningIcon>
                  </SpinningStyleWrapper>
                ) : (
                  getExtendRenewButtonText()
                )}
              </ExtendButton>
            ) : (
              // Go to dashboard button
              <Button
                setting="mtr"
                type="button"
                onClick={() => handleGoToDashboardButton()}
              >
                {t('ENTERPRISE_GO_TO_DASHBOARD_BUTTON')}
              </Button>
            )}
          </FlexButtonWrapper>
        </Modal>
      </MobileWrapper>
    </>
  );
};
