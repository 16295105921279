/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { AttachmentTile } from '@dsny/dsny-component-library';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { format } from 'date-fns-tz';
import { convertDateToUTC } from 'src/utils/Formatter';
import {
  DesktopWrapper,
  MobileWrapper,
} from './EnterpriseExtendSongModal.styles';

export const EnterpriseSelectedSong = () => {
  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  if (!selectedSong) {
    return null;
  }

  // Getting start date
  const getStartDate = () => {
    return selectedSong?.selected_tracking_period?.remainingDays > 1
      ? new Date(selectedSong?.tracking_periods[0].end_tracking_on)
      : new Date();
  };

  // Getting end date
  const getEndDate = () => {
    const newDate = getStartDate();
    newDate.setMonth(newDate.getMonth() + 1);

    return newDate;
  };

  return (
    <>
      {/* Desktop view */}
      <DesktopWrapper>
        <AttachmentTile
          setting="neutral"
          {...{
            attachment: {
              id: selectedSong.song_id.toString(),
              uploaduuid: '',
              filename: selectedSong.title,
              title: selectedSong.title || '',
              size: 0,
              type: 'audio/wav',
            },
            uploadedFiles: {},
            // eslint-disable-next-line no-console
            onDelete: () => console.log('delete'),
          }}
          displayActionIcon={false}
          text={`${selectedSong.artist} | ${selectedSong.title}`}
          subText={`Tracking period: ${format(
            convertDateToUTC(getStartDate()),
            'dd, MMMM, yyyy'
          )} - ${format(convertDateToUTC(getEndDate()), 'dd, MMMM, yyyy')}`}
          isUploading={false}
        />
      </DesktopWrapper>

      {/* Mobile view */}
      <MobileWrapper>
        <AttachmentTile
          setting="neutral"
          {...{
            attachment: {
              id: selectedSong.song_id.toString(),
              uploaduuid: '',
              filename: selectedSong.title,
              title: selectedSong.title || '',
              size: 0,
              type: 'audio/wav',
            },
            uploadedFiles: {},
            // eslint-disable-next-line no-console
            onDelete: () => console.log('delete'),
          }}
          displayActionIcon={false}
          text={`${selectedSong.artist} | ${selectedSong.title}`}
          subText={`${format(
            convertDateToUTC(
              new Date(selectedSong?.tracking_periods[0].start_tracking_on)
            ),
            'MM/dd/yy'
          )} - ${format(
            convertDateToUTC(
              new Date(selectedSong?.tracking_periods[0].end_tracking_on)
            ),
            'MM/dd/yy'
          )}`}
          isUploading={false}
        />
      </MobileWrapper>
    </>
  );
};
