import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from 'src/styles/global';
import { Button } from '@dsny/dsny-component-library';

export const LogoMTRWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 24px;

  @media ${device.mobile} {
    padding-top: 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 676px;
  min-height: 443px;
  padding-bottom: 32px;

  @media ${device.mobile} {
    max-width: 288px;
    padding-bottom: 16px;
  }
  @media ${device.tablet} {
    width: 90%;
  }
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 32px;

  @media ${device.mobile} {
    margin-bottom: 24px;
  }
`;

export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 80px 104px;
  border-radius: 5px;
  border-top: 4px solid ${({ theme }) => theme.colors.mtr60};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.background.white};

  @media ${device.mobile} {
    padding: 48px 24px;
  }
`;

export const Title = styled.div`
  padding-bottom: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 20px;
    padding-bottom: 32px;
  }
`;

export const RememberMeSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 32px;

  @media ${device.mobile} {
    flex-direction: column;
    padding-bottom: 24px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutralW40};

  @media ${device.mobile} {
    padding-bottom: 16px;
  }
`;

export const ForgotPasswordLink = styled(Link)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.mtr60};
  text-decoration: none;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 32px;
`;

export const ButtonWrapper = styled(Button)`
  width: 100%;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const QuestionMarkWrapper = styled.span`
  color: ${({ theme }) => theme.colors.blackTwo};
`;

export const LoaderImg = styled.img`
  width: 16px;
  height: 16px;
`;

export const NotMemberSection = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutralW10};
`;

export const JoinCommunityLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mtr60};
  cursor: pointer;
  padding-left: 1px;
  padding-right: 1px;

  &:focus-visible {
    outline: 2px solid;
  }
`;
