import styled from 'styled-components';
import theme from 'src/styles/theme';
import { device } from 'src/styles/global';
import { Tonality } from '@dsny/dsny-component-library';

interface Props {
  selectedItemIndex?: number;
  $inlineMessage?: boolean;
}

export const ComboboxWrapper = styled.div`
  width: 100%;
  padding-bottom: 24px;

  @media ${device.mobile} {
    display: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const ComboboxWrapperCountry = styled.div`
  width: 100%;

  @media ${device.mobile} {
    display: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const ComboboxWrapperState = styled.div`
  width: 100%;
  padding-top: 24px;

  @media ${device.mobile} {
    display: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const ComboboxStyles = {
  width: '100%',
};

export const ComboboxInputStyles = {
  color: theme.colors.neutralW40,
  height: '44px',
};

export const InputLabelStyles = {
  marginLeft: '5px',
};

export const InputLabelStylesPromotedSongs = {
  marginLeft: '-4px',
};

export const ComboboxPopoverStyles = {
  width: '100%',
  height: 'fit-content',
  borderRadius: '5px',
  marginTop: '8px',
  borderColor: theme.colors.white,
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
  overflow: 'hidden',
};

export const ComboboxPopoverStylesCountryState = {
  width: '100%',
  height: '216px',
  borderRadius: '5px',
  marginTop: '8px',
  borderColor: theme.colors.white,
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
  overflowx: 'scroll',
};

export const ComboboxOptionWrapper = styled(Tonality.ComboboxOption)<Props>`
  &:hover {
    background: ${theme.colors.neutralW100};
  }

  &:nth-child(${({ selectedItemIndex }) => selectedItemIndex}) {
    background: ${theme.colors.mtr100};
  }
`;

export const ItemWrapper = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW40};
`;

export const InlineMessage = styled.p<Props>`
  color: ${theme.colors.error50};
  font-size: 12px;
  max-height: ${({ $inlineMessage }) => ($inlineMessage ? '20px' : '0')};
  transition: ${({ $inlineMessage }) =>
    $inlineMessage ? 'max-height 0.25s ease-in' : 'max-height 0.15s ease-out'};
  overflow: hidden;
  margin-top: 8px;
`;
