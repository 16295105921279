import styled from 'styled-components';
import { device } from 'src/styles/global';
import theme from 'src/styles/theme';

export const TrackListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 437px;
  overflow-y: auto;
  border: none;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.neutralW90};
    border-radius: 10px;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.neutralW90};
`;

export const DesktopWrapper = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  @media ${device.tablet} {
    display: none;
  }

  @media ${device.ipad} {
    display: none;
  }

  @media ${device.laptop} {
    display: none;
  }

  @media ${device.desktop} {
    display: none;
  }
`;
