import styled from 'styled-components';
import theme from 'src/styles/theme';
import { device } from 'src/styles/global';

export const ModalContainerStyles = {
  width: '608px',
  padding: '32px',
};

export const ModalHeadingWrapper = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${theme.colors.neutralW20};
  margin-bottom: 8px;
`;

export const ModalDescWrapper = styled.div`
  font-size: 14px;
  color: ${theme.colors.neutralW20};
  margin-bottom: 32px;

  @media ${device.mobile} {
    margin-bottom: 24px;
  }
`;

export const FlexButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;
