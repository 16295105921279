export const promotedSongsData = [
  {
    index: 1,
    id: '1',
    description: '1-10',
  },
  {
    index: 2,
    id: '2',
    description: '10-50',
  },
  {
    index: 3,
    id: '3',
    description: '50-100',
  },
  {
    index: 4,
    id: '4',
    description: '100+',
  },
];
