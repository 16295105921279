import { device } from 'src/styles/global';
import styled from 'styled-components';
import theme from 'src/styles/theme';

interface Props {
  isOpen: boolean;
}

export const AccountDropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const AccountDropdownBox = styled.div`
  position: absolute;
  top: 48px;
  right: 0;
  background: white;
  border: 1px solid ${theme.colors.mtr60};
  border-radius: 5px;
  width: 320px;
`;

export const AccountBadgeContainer = styled.div`
  padding: 16px 16px 0px;
`;

export const AccountBadgeBox = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 8px 16px;
  margin-bottom: 8px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.neutralW100};
  gap: 16px;
`;

export const AccountDropdownList = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 8px;
`;

export const AccountBadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media ${device.mobile} {
    display: none;
  }
`;

export const AccountBadgeDesktopWrapper = styled.div`
  display: flex;

  @media ${device.mobile} {
    display: none;
  }
`;

export const AccountNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccountDropdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    display: none;
  }
`;

export const AccountDropdownButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  color: ${theme.colors.neutralW40};
  outline: none;
  border: none;
  border-radius: 5px;
  background: none;
  cursor: pointer;
  padding: 0px;
  gap: 8px;
`;

export const AccountDropdownDesc = styled.div<Props>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: flex-start;
  font-size: 12px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    display: none;
  }
`;

export const StationListButton = styled.button`
  display: flex;
  font-size: 16px;
  color: ${theme.colors.neutralW40};
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  gap: 8px;
  width: 100%;
  padding: 10px 16px 10px;

  &:hover {
    background: ${theme.colors.neutralW100};
  }

  svg {
    width: 20px;
    height: 20px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const PreferencesButton = styled.button`
  display: flex;
  font-size: 16px;
  color: ${theme.colors.neutralW40};
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  gap: 8px;
  width: 100%;
  padding: 10px 16px 10px;

  &:hover {
    background: ${theme.colors.neutralW100};
  }

  svg {
    align-self: center;
    width: 20px;
    height: 20px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const SignoutButton = styled.button`
  display: flex;
  font-size: 16px;
  color: ${theme.colors.neutralW40};
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;
  gap: 8px;
  width: 100%;
  padding: 10px 16px 10px;
  margin-bottom: 8px;
  border-top: 1px solid ${theme.colors.neutralW100};

  &:hover {
    background: ${theme.colors.neutralW100};
  }

  svg {
    align-self: center;
    width: 20px;
    height: 20px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.neutralW100};
  }
`;
