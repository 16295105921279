import { Text } from '@dsny/dsny-component-library';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getSessionUser, logout, UserInfo } from 'src/features/Auth';
import theme from 'src/styles/theme';
import {
  GroupsUnfilled,
  Logout,
  Keyboardarrowup,
  Keyboardarrowdown,
  Radio,
  Settings,
  Singlerecord,
} from '@dsny/dsny-component-library/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import {
  AddPaymentObj,
  getTeamMembers,
  MemberStatus,
} from 'src/features/Purchase/AddPayment';
import { formatEmailToShort } from 'src/utils/Formatter';
import { resetIdentifyUser, trackEvent } from 'src/utils/segmentService';
import RadioStationModal from 'src/features/RadioStationModal';
import { useNavigate } from 'react-router-dom';
import {
  SignoutButton,
  AccountDropdownBox,
  AccountNameWrapper,
  AccountBadgeWrapper,
  AccountDropdownItem,
  AccountBadgeDesktopWrapper,
  StationListButton,
  AccountDropdownList,
  PreferencesButton,
  AccountBadgeBox,
  AccountDropdownButton,
  AccountDropdownDesc,
  IconWrapper,
  AccountDropdownContainer,
  AccountBadgeContainer,
} from './AccountDropdown.styles';
import AccountBadges from '../AccountBadges/AccountBadges';

interface AccountDropdownProps {
  userInfo?: UserInfo; // Optional `userInfo` prop
}

const AccountDropdown: React.FC<AccountDropdownProps> = ({ userInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isTeamSpaceVisible, setTeamSpaceVisible] = useState(false);
  const [isEnterpriseLimitVisible, setEnterpriseLimitVisible] = useState(false);
  const [showStationModal, setShowStationModal] = useState(false);
  const navigate = useNavigate();

  const { teamMember }: AddPaymentObj = useSelector(
    (state: RootState) => state.addPayment
  );

  const { enterpriseProfile } = useSelector(
    (state: RootState) => state.dashboard
  );

  // Getting session user fullname and email
  const { fullname, email } = getSessionUser();

  useEffect(() => {
    const { display } = getSessionUser();
    if (display && !teamMember?.memberStatus) {
      dispatch(getTeamMembers());
    }
  }, [teamMember]);

  // Handling signout
  const signOut = async () => {
    const isUserLoggedOut = await logout();
    if (isUserLoggedOut) {
      resetIdentifyUser();
      trackEvent('Logout', {}, () => {
        window.location.reload();
      });
      navigate('/');
    }
  };

  // Handling toggle dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Closes dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Close dropdown when modal is opened
  useEffect(() => {
    if (showStationModal) {
      setIsDropdownOpen(false);
    }
  }, [showStationModal]);

  // Handling dropdown navigation
  const handleNavigation = () => {
    setIsDropdownOpen(false);
    navigate('/billing');
  };

  // Handling team space toggle
  const teamSpaceToggle = () => {
    setTeamSpaceVisible((prev) => !prev);
  };

  // Handling enterprise limit toggle
  const enterpriseLimitToggle = () => {
    setEnterpriseLimitVisible((prev) => !prev);
  };

  return (
    // Account Dropdowm
    <AccountDropdownContainer ref={dropdownRef}>
      {/* Account badge */}
      <AccountBadgeDesktopWrapper>
        <AccountBadges
          avatarColor={userInfo?.color || ''}
          avatarInitals={userInfo?.initials || ''}
          onClick={toggleDropdown}
        />
      </AccountBadgeDesktopWrapper>

      {isDropdownOpen && (
        <AccountDropdownBox>
          {/* Account info */}
          <AccountBadgeContainer>
            <AccountBadgeBox>
              {/* Avatar */}
              <AccountBadgeWrapper>
                <AccountBadgeDesktopWrapper>
                  <AccountBadges
                    avatarColor={userInfo?.color || ''}
                    avatarInitals={userInfo?.initials || ''}
                  />
                </AccountBadgeDesktopWrapper>
                <AccountNameWrapper>
                  <Text
                    ellipsis
                    maxWidth="216px"
                    color={theme.colors.neutralW40}
                  >
                    {fullname}
                  </Text>
                  <Text
                    fontSize="12px"
                    ellipsis
                    maxWidth="216px"
                    color={theme.colors.neutralW40}
                  >
                    {formatEmailToShort(email)}
                  </Text>
                </AccountNameWrapper>
              </AccountBadgeWrapper>

              {/* Enterprise limit */}
              {enterpriseProfile.isEnterpriseProfile && (
                <>
                  <AccountDropdownItem onClick={enterpriseLimitToggle}>
                    <AccountDropdownButton>
                      <Singlerecord />
                      {t('ENTERPRISE_PLAN_LIMIT')}{' '}
                      {enterpriseProfile.active_songs_count}/
                      {enterpriseProfile.songs_limit}
                    </AccountDropdownButton>
                    {isEnterpriseLimitVisible ? (
                      <IconWrapper>
                        <Keyboardarrowup />
                      </IconWrapper>
                    ) : (
                      <IconWrapper>
                        <Keyboardarrowdown />
                      </IconWrapper>
                    )}
                  </AccountDropdownItem>
                  <AccountDropdownDesc isOpen={isEnterpriseLimitVisible}>
                    <Trans
                      i18nKey="ENTERPRISE_PLAN_LIMIT_ACCOUNT_DESC"
                      values={{ limit: enterpriseProfile.songs_limit }}
                    />
                  </AccountDropdownDesc>
                </>
              )}

              {/* Team space */}
              {teamMember?.memberStatus === MemberStatus.TEAM_MEMBER && (
                <>
                  <AccountDropdownItem onClick={teamSpaceToggle}>
                    <AccountDropdownButton>
                      <GroupsUnfilled width={24} height={24} display="flex" />
                      {t('TEAM_SPACE_NAV_CTA')}
                    </AccountDropdownButton>
                    {isTeamSpaceVisible ? (
                      <IconWrapper>
                        <Keyboardarrowup />
                      </IconWrapper>
                    ) : (
                      <IconWrapper>
                        <Keyboardarrowdown />
                      </IconWrapper>
                    )}
                  </AccountDropdownItem>
                  <AccountDropdownDesc isOpen={isTeamSpaceVisible}>
                    {t('TEAM_SPACE_DESC')}
                  </AccountDropdownDesc>
                </>
              )}
            </AccountBadgeBox>
          </AccountBadgeContainer>

          <AccountDropdownList>
            {/* Station list modal button */}
            <div>
              <StationListButton onClick={() => setShowStationModal(true)}>
                <Radio />
                {t('NAVBAR_STATION_LIST_MODAL')}
              </StationListButton>
            </div>

            {/* Billing preferences button */}
            <div>
              <PreferencesButton onClick={() => handleNavigation()}>
                <Settings />
                {t('NAVBAR_PREFERENCES_BUTTON')}
              </PreferencesButton>
            </div>

            {/* Signout button */}
            <SignoutButton onClick={signOut}>
              <Logout />
              {t('NAVBAR_SIGNOUT_CTA_BUTTON')}
            </SignoutButton>
          </AccountDropdownList>
        </AccountDropdownBox>
      )}
      {/* Station list modal */}
      <RadioStationModal
        showStationModal={showStationModal}
        setShowStationModal={setShowStationModal}
      />
    </AccountDropdownContainer>
  );
};

export default AccountDropdown;
