/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { convertDateToUTC } from 'src/utils/Formatter';
import { format } from 'date-fns-tz';
import { Trans, useTranslation } from 'react-i18next';
import {
  isExtendDayOne,
  isExtendWithinNinetyDays,
  isFirstPurchase,
} from 'src/utils/VerifyTrackStatus';
import {
  SubTitle,
  Title,
} from '../Purchase/SelectPackage/SelectPackage.styles';

import { AddTrackList } from '../Purchase/MultiAsset';

const KeepTrackingScenarios: React.FC = () => {
  const { t } = useTranslation();

  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const { tracks }: AddTrackList = useSelector(
    (state: RootState) => state.multiAsset
  );

  const { enterpriseProfile } = useSelector(
    (state: RootState) => state.dashboard
  );

  // Getting title and description based on 4 scenarios
  const getSelectPackageText = () => {
    /* Scenario 0 - first purchase or subsequent ones */
    if (isFirstPurchase(tracks)) {
      return (
        <>
          <Title>{t('SELECT_PACKAGE_MULTI_ASSET_TITLE')}</Title>
          <SubTitle>
            <Trans
              i18nKey="SELECT_PACKAGE_MULTI_ASSET_SUBTITLE"
              values={{
                number: tracks.length,
              }}
              components={{ bold: <strong /> }}
            />
          </SubTitle>
        </>
      );
    }

    /* Scenario 1 (extend) - active, warning and day 1 of expiration */
    if (selectedSong && isExtendDayOne(selectedSong)) {
      const currentDate = new Date(); // Get the current date
      const { remainingDays } = selectedSong.selected_tracking_period; // Get the remaining days from selectedSong

      const extensionStartDate = new Date();
      const days = remainingDays < 0 ? 0 : remainingDays;
      extensionStartDate.setDate(currentDate.getDate() + days);

      const formattedExtensionStartDate = format(
        convertDateToUTC(new Date(extensionStartDate)),
        'MMM dd, yyyy'
      );

      // Enterprise user
      return enterpriseProfile.isEnterpriseProfile ? (
        <>
          <Trans
            i18nKey="ENTERPRISE_EXTEND_ACTIVE_SONG_MODAL_DESC"
            values={{ enddate: formattedExtensionStartDate }}
            components={{ bold: <strong /> }}
          />
        </>
      ) : (
        <>
          <Title>{t('SELECT_PACKAGE_EXTEND_TITLE')}</Title>
          <SubTitle>
            <Trans
              i18nKey="SELECT_PACKAGE_SUBTITLE_ACTIVE_WARNING"
              values={{ extensionStartDate: formattedExtensionStartDate }}
              components={{ bold: <strong /> }}
            />
          </SubTitle>
        </>
      );
    }

    /* Scenario 2 (extend) -  expired within 90 day period - starting day 2 of expiration until day 90  */
    if (selectedSong && isExtendWithinNinetyDays(selectedSong)) {
      const { remainingDays } = selectedSong.selected_tracking_period;

      // Enterprise user
      return enterpriseProfile.isEnterpriseProfile ? (
        <>
          <Trans
            i18nKey="ENTERPRISE_EXTEND_EXPIRED_SONG_MODAL_DESC"
            values={{
              number: Math.abs(remainingDays),
              day: Math.abs(remainingDays) > 0 ? 'days' : 'day',
            }}
            components={{ bold: <strong /> }}
          />
        </>
      ) : (
        <>
          <Title>{t('SELECT_PACKAGE_EXTEND_TITLE')}</Title>
          <SubTitle>
            <Trans
              i18nKey="SELECT_PACKAGE_SUBTITLE_WITHIN_90"
              values={{
                number: Math.abs(remainingDays),
                day: Math.abs(remainingDays) > 0 ? 'days' : 'day',
              }}
              components={{ bold: <strong /> }}
            />
          </SubTitle>
        </>
      );
    }

    /* Scenario 3 (renew) -  expired past 90 day period - starting day 91  */

    // Enterprise user
    return enterpriseProfile.isEnterpriseProfile ? (
      <>{t('ENTERPRISE_RENEW_EXPIRED_SONG_MODAL_DESC')}</>
    ) : (
      <>
        <Title>{t('SELECT_PACKAGE_RENEW_TITLE')}</Title>
        <SubTitle>
          <Trans
            i18nKey="SELECT_PACKAGE_SUBTITLE"
            components={{ bold: <strong /> }}
          />
        </SubTitle>
      </>
    );
  };

  return <>{getSelectPackageText()}</>;
};

export default KeepTrackingScenarios;
