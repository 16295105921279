import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import {
  ImportantNoteWrapper,
  SubTitleRelease,
  Title,
} from './StepOneTitle.styles';

const StepOneTitleEnterpriseRelease: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Step-one title and subtitles - (Enterprise Import Release from Caster) */}
      <Title>{t('IMPORT_RELEASE_TITLE')}</Title>
      <SubTitleRelease>
        <Text fontSize="14px" color={theme.colors.neutralW40}>
          {t('IMPORT_RELEASE_ENTERPRISE_SUBTITLE')}
        </Text>

        <Text fontSize="14px" color={theme.colors.neutralW40}>
          {t('IMPORT_RELEASE_ENTERPRISE_NOTE')}
        </Text>
      </SubTitleRelease>

      <ImportantNoteWrapper>
        <Trans
          i18nKey="IMPORT_RELEASE_IMPORTANT"
          components={{ bold: <strong /> }}
        />
      </ImportantNoteWrapper>
    </>
  );
};

export default StepOneTitleEnterpriseRelease;
